import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import s from './SocialMedia.scss';
import { HeaderLink } from '../header/HeaderLink';
import Facebook from '../../assets/svg/facebook.svg';
import Instagram from '../../assets/svg/instagram.svg';
import { useAppDispatchContext, useAppStateContext } from '../../context/appContext';

interface SocialMediaProps {
  menu: boolean,
  footer: boolean
}

export const SocialMedia = ({ menu, footer } : SocialMediaProps) => {
  const intl = useIntl();

  const dispatch = useAppDispatchContext();
  const onCursor = cursorType => {
    cursorType = cursorType ? cursorType : false;
    dispatch({ type: 'CURSOR_TYPE', cursorType: cursorType });
  };

  return (
    <div>
      { menu && <hr className={s(s.social__separator, { social: true })}/> }
      <div className={s(s.social__social, { menu: menu, footer: footer})}>
        {intl.formatMessage({id: 'social'})}
      </div>
      <div className={s(s.social__socialIcons, {footer: footer})}>
        <div
          onMouseOver={() => onCursor('action')}
          onMouseLeave={() => onCursor()}
        >
          <HeaderLink to="https://www.facebook.com/Intobello-100343498147352" icon={<Facebook />}/>
        </div>
        <div
          onMouseOver={() => onCursor('action')}
          onMouseLeave={() => onCursor()}
        >
          <HeaderLink to="https://www.instagram.com/into.bello" icon={<Instagram />}/>
        </div>
      </div>
      { menu && <hr className={s(s.social__separator, { social: true , socialSecond: true})}/> }
    </div>
  );
};
