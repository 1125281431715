export const RingSize = {
  S: 34,
  M: 88,
  L: 202,
  XL: 330,
};

export const RingCollor = {
  'accent': '#7d133b',
  'white': '#FFFFFF',
};

//collection
export const collectionHomeHover ={
  size: RingSize.XL,
  color: RingCollor.accent,
  text: '',
  eyeIcon: false,
};

export const collectionMenuHover ={
  size: RingSize.L,
  color: RingCollor.accent,
  text: '',
  eyeIcon: false,
};

//zoom
export const collectionCoverHover ={
  size: RingSize.M,
  color: RingCollor.white,
  text: 'See the collection',
  eyeIcon: true,
};

export const furnitureCoverHover ={
  size: RingSize.M,
  color: RingCollor.white,
  text: 'Hold the cursor and see front view',
  eyeIcon: true,
};

//pressed
export const collectionCoverPressed ={
  size: RingSize.L,
  color: RingCollor.white,
  text: 'See the collection',
  eyeIcon: true,
};

export const furnitureCoverPressed ={
  size: RingSize.L,
  color: RingCollor.white,
  text: 'Hold the cursor and see front view',
  eyeIcon: true,
};

//furnitureSlider
export const furnitureSliderHover ={
  size: RingSize.S,
  color: RingCollor.white,
  text: '',
  eyeIcon: true,
};

//sliderArrow
export const action ={
  size: RingSize.M,
  color: RingCollor.accent,
  text: '',
  eyeIcon: false,
};

export const actionWhite ={
  size: RingSize.M,
  color: RingCollor.white,
  text: '',
  eyeIcon: false,
};

//white
export const whiteDefault ={
  size: RingSize.S,
  color: RingCollor.white,
  text: '',
  eyeIcon: false,
};

export const opacitySensitiveCursorType = [
  "collectionCoverHover",
  "furnitureCoverHover",
  "collectionCoverPressed",
  "furnitureCoverPressed",
  "furnitureSliderHover"
];
