import React, { createContext, useReducer, useContext } from 'react';

const CurtainStateContext = createContext({});
const CurtainDispatchContext = createContext({});

const curtainReducer = (state, action) => {
  switch (action.type) {
    case 'SET_INITIAL_FALSE': {
      return {
        ...state,
        isInitial: false,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export const CurtainStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(curtainReducer, { isInitial: true });
  
  return(
    <CurtainDispatchContext.Provider value={dispatch}>
      <CurtainStateContext.Provider value={state}>
        {children}
      </CurtainStateContext.Provider>
    </CurtainDispatchContext.Provider>
  )
};
export const useCurtainStateContext = () => useContext(CurtainStateContext);
export const useCurtainDispatchContext = () => useContext(CurtainDispatchContext);
