import React from 'react';
import { AppProvider } from './src/context/appContext';
import { CurtainStateProvider } from './src/context/curtainContext';

export const wrapRootElement = ({ element }) => {

  // Uncomment for Product Launch
  console.log('%cMade with love by Decode.technology', 'background:#00BA90;color:#fff');
  
  return <AppProvider><CurtainStateProvider>{element}</CurtainStateProvider></AppProvider>;
};
