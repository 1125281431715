import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { IntlContextConsumer } from 'gatsby-plugin-intl';
import { gsap } from 'gsap';
import { Helmet } from 'react-helmet';

import { useCurtainDispatchContext } from '../../context/curtainContext';
import { helmet } from '../../utils/helmet';

import { Cursor } from '../cursor/Cursor';
import { Footer } from '../footer/Footer';
import { Header, HeaderColors } from '../header/Header';
import { Menu } from '../menu/Menu';
import { Cookies } from '../cookies/Cookies';

import s from './AppLayout.scss';

interface AppLayoutProps {
  children: ReactNode;
}

export default ({ children }: AppLayoutProps) => {
  const isHomePage = children.props.location.pathname === '/' || children.props.location.pathname === '/fr/';
  const isSubmittedPage = children.props.location.pathname === '/thank-you'
    || children.props.location.pathname === '/try-another-way';

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isScreenCovered, setIsScreenCovered] = useState(false);
  const dispatch = useCurtainDispatchContext();

  const isSecondaryHeader = isHomePage || isSubmittedPage || isMenuOpen;

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const tl = gsap.timeline();

  const refCurtainBackground = useRef<HTMLDivElement>(null);
  const refCurtainColor = useRef<HTMLDivElement>(null);

  const refLogo = useRef<HTMLDivElement>(null);
  const borderLeft = useRef<HTMLDivElement>(null);
  const borderRight = useRef<HTMLDivElement>(null);
  const hamburger = useRef<HTMLDivElement>(null);
  const language = useRef<HTMLDivElement>(null);
  const languageText = useRef<HTMLDivElement>(null);
  const refHeader = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (refLogo.current) {
      if (isSecondaryHeader || isScreenCovered) {
        gsap.set(refLogo.current.children[0].children, { fill: '#ffffff' });
      } else {
        gsap.set(refLogo.current.children[0].children, { fill: 'unset' });
      }
    }
  });

  useEffect(() => {
    if (refLogo.current && hamburger.current && language.current && languageText.current && refHeader.current) {
      gsap.set(refLogo.current, { autoAlpha: 0, scale: 2, y: '20em' });
      gsap.set(hamburger.current, { autoAlpha: 0 });
      gsap.set(language.current, { autoAlpha: 0 });
      gsap.set(languageText.current, { autoAlpha: 0 });
      gsap.set(refHeader.current, { autoAlpha: 0 });

      tl.to(refCurtainColor.current, {
        xPercent: 0,
        left: 0,
        duration: 1.3,
        ease: 'power4.easeInOut',
        onStart: () => setIsScreenCovered(true),
      })
        .set(refCurtainBackground.current, {
          backgroundColor: 'transparent',
        })
        .set(refHeader.current, { autoAlpha: 1 })
        .addLabel('start')
        .to(refCurtainColor.current, {
          duration: 1.3,
          delay: 1.5,
          xPercent: 100,
          ease: 'power4.out',
        }, 'start');
      tl.set(refCurtainBackground.current, {
        autoAlpha: 0,
      });

      if (!isSecondaryHeader) {
        const logoSVG = refLogo.current.children[0].children;
        tl.set(logoSVG, { fill: '#ffffff' }, 'start-=0.1');
        tl.to(logoSVG, { fill: '#000000', duration: 1 }, 'start+=1.5');
      }

      tl.to(refLogo.current, {
        duration: .4,
        autoAlpha: 1,
      }, 'start');

      tl.to(refLogo.current, {
        duration: 1,
        scale: 1,
        y: 0,
        ease: 'power2.out',
      }, 'start+=1.5');

      tl.addLabel('header', '-=1');

      tl.fromTo(borderLeft.current, {
        scaleX: 0,
        transformOrigin: 'right center',
      }, {
        scaleX: 1,
        duration: 2,
        ease: 'power4.easeInOut',
        onStart: () => {
          setIsScreenCovered(false);
          setTimeout(() => dispatch({ type: 'SET_INITIAL_FALSE' }), 300);
        },
      }, 'header');

      tl.fromTo(borderRight.current, {
          scaleX: 0,
          transformOrigin: 'left center',
        },
        {
          scaleX: 1,
          duration: 2,
          ease: 'power4.easeInOut',
        }, 'header');

      tl.addLabel('icons', '2.7');

      tl.fromTo([hamburger.current, language.current, languageText.current], {
          autoAlpha: 0,
        },
        {
          duration: 1,
          delay: 1.8,
          autoAlpha: 1,
          ease: 'power2.out',
        }, 'icons');
    }
  }, []);

  return (
    <div className={s.layout}>
      <Helmet {...helmet} />
      <Menu isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
      <IntlContextConsumer>
        {({ language: currentLang }) => (
          <Header color={isSecondaryHeader ? HeaderColors.WHITE : HeaderColors.BLACK}
                  isMenuOpen={isMenuOpen}
                  toggleMenu={toggleMenu}
                  forwardRefLogo={refLogo}
                  forwardedRefborderLeft={borderLeft}
                  forwardedRefborderRight={borderRight}
                  forwardedRefhamburger={hamburger}
                  forwardedRefLanguage={language}
                  forwardedRefLanguageText={languageText}
                  currentLang={currentLang}
                  refWrapper={refHeader}
          />
        )}
      </IntlContextConsumer>
      <div className={s.intro__wrapper} ref={refCurtainBackground}>
        <div className={s.intro} ref={refCurtainColor} />
      </div>
      {children}
      <Cursor />
      <Footer />
      <Cookies />
      {/*{isDev && <Devtools />}*/}
    </div>
  );
};

