import { useEffect } from 'react';
import * as React from 'react';
import { animated as a, useSpring } from 'react-spring';
import { RingSize, RingCollor } from './CurosrConfig'

import s from './Ring.scss';

export const Ring = ({ cursorType }) => {
  const ringSize = cursorType ? cursorType.size : RingSize.S;

  const [props, set] = useSpring(() => ({
    r: ringSize,
    config: {
      tension: 280,
      friction: 60,
      mass: 5,
    },
  }));
  set({ r: ringSize });

  return (
    <div>
      <svg
        className={s.ring}
        viewBox="0 0 680 680"
      >
        <a.circle fill='none'
                  stroke={cursorType ? cursorType.color : RingCollor.accent}
                  strokeWidth='1'
                  strokeMiterlimit='10' cx='340' cy='340'
                  r={props.r}
        />
      </svg>
    </div>
  );
};
