import React, { createContext, useReducer, useContext } from 'react';

const AppStateContext = createContext({});
const AppDispatchContext = createContext({});

const appReducer = (state, action) => {
  switch (action.type) {
    case 'CURSOR_TYPE': {
      return {
        ...state,
        cursorType: action.cursorType,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, {
    cursorType: undefined,
    cursorStyles: [
      'collectionHomeHover',
      'collectionCoverHover',
      'furnitureCoverHover',
      'collectionCoverPressed',
      'furnitureCoverPressed',
      'whiteDefault',
      'furnitureSliderHover',
      'action',
      'actionWhite',
      'collectionMenuHover',
    ],
  });

  return (
    <AppDispatchContext.Provider value={dispatch}>
      <AppStateContext.Provider value={state}>
        {children}
      </AppStateContext.Provider>
    </AppDispatchContext.Provider>
  );
};

export const useAppStateContext = () => useContext(AppStateContext);
export const useAppDispatchContext = () => useContext(AppDispatchContext);
