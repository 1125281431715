import React, { useEffect, useRef } from 'react';
import s from './cookies.scss';
import { useCookie } from 'hooks/use-cookie';

import { Container } from '../container/Container';
import { Button } from '../button/Button';
import { useIntl } from 'gatsby-plugin-intl';
import { gsap } from 'gsap';

export const Cookies = () => {
  const [cookie, updateCookie] = useCookie('Intobello-Cookie', false);
  const wrapperRef = useRef(null);

  const addCookie = () => {
    updateCookie(true, 10);
  };

  const intl = useIntl();

  useEffect(() => {
    const tl = gsap.timeline();

    if (wrapperRef.current !== null) {
      tl.set(wrapperRef.current, {
        yPercent: 100
      });
      tl.to(wrapperRef.current, {
        duration: 3.5,
        delay: 6,
        yPercent: 0,
        ease: 'expo',
      });
    }

    return () => tl.kill();
  }, []);

  return (
    !cookie && <div className={s(s.cookies, { inVisible: cookie })} ref={wrapperRef}>
      <Container>
        <div className={s.cookies__all}>
          <div className={s.cookies__header}>
            <p className={s.cookies__header__text}>Cookies</p>
          </div>
          <div className={s.cookies__content}>
            <p className={s.cookies__text}>
              {intl.formatMessage({ id: 'cookie1' })}
              <a target="_blank" rel="noopener noreferrer" className={s.cookies__link}
                 href={'https://intobello.com/PersonalDataProtectionPolicy.pdf'}>{intl.formatMessage({ id: 'privacyPolicy' })}</a>
              {intl.formatMessage({ id: 'cookie2' })}
            </p>
          </div>
          <div className={s.cookies__button}>
            <Button onClick={addCookie}>
              {intl.formatMessage({ id: 'cookieButton' })}
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
};
