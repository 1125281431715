import { graphql, useStaticQuery } from 'gatsby';

export const useQueryCollections = (): any => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query CollectionsQuery {
        allMarkdownRemark(filter: { frontmatter: { language: { eq: "en" } } }, sort: { order: ASC, fields: [frontmatter___id] }) {
          nodes {
            frontmatter {
              id
              path
              title
              featured {
                childImageSharp {
                  fluid(maxWidth: 160) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              miniatures {
                top
                left
                cover {
                  childImageSharp {
                    fixed(width: 636, height: 358) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
                width
                height
              }
            }
          }
        }
      }
    `
  );
  return (allMarkdownRemark);
};
