import React, { useEffect } from 'react';
import { useAppStateContext } from 'context/appContext';
import { useMobile } from 'hooks/use-mobile';
import { animated as a, useSpring } from 'react-spring';
import { useMove } from 'react-use-gesture';
import { Ring, RingSize } from './Ring';
import { collectionHomeHover, collectionMenuHover, collectionCoverHover, furnitureCoverHover, collectionCoverPressed, furnitureCoverPressed, furnitureSliderHover, action, actionWhite, whiteDefault, opacitySensitiveCursorType} from './CurosrConfig'
import { Circle } from './Circle';

import Eye from './../../assets/svg/eye.svg';
import s from './Cursor.scss';

export const cursorTypeConfig = {
  'collectionCoverHover': collectionCoverHover,
  'furnitureCoverHover': furnitureCoverHover,
  'collectionCoverPressed': collectionCoverPressed,
  'furnitureCoverPressed': furnitureCoverPressed,
  'whiteDefault': whiteDefault,
  'collectionHomeHover': collectionHomeHover,
  'furnitureSliderHover': furnitureSliderHover,
  'action': action,
  'actionWhite': actionWhite,
  'collectionMenuHover': collectionMenuHover
};

export const Cursor = () => {
    const isMobile = useMobile();
    const { cursorType } = useAppStateContext();

    const [{ xy, hovering }, setPointer] = useSpring(() => ({
      xy: [0, 0],
      hovering: 0,
      config: {
        tension: 350,
        friction: 10,
        mass: 0.1,
      },
    }));
    const [{ xyText }, setText] = useSpring(() => ({
      xyText: [0, 0],
      config: {
        tension: 210,
        friction: 20,
        mass: 0.4,
      },
    }));

    const [{ xyRing }, setRing] = useSpring(() => ({
      xyRing: [0, 0],
    }));

    const bindPointer = useMove((d) => setPointer({ xy: d.xy }), {
      domTarget: typeof window === 'undefined' ? null : window,
    });
    const bindText = useMove((d) => setText({ xyText: d.xy }), {
      domTarget: typeof window === 'undefined' ? null : window,
    });
    const bindRing = useMove((d) => setRing({ xyRing: d.xy }), {
      domTarget: typeof window === 'undefined' ? null : window,
    });

    useEffect(bindPointer as any, [bindPointer]);
    useEffect(bindRing as any, [bindRing]);
    useEffect(bindText as any, [bindText]);

    useEffect(() => {
      setPointer({ hovering: Number( opacitySensitiveCursorType.indexOf(cursorType) > -1 ) });
    });

    const opacity = hovering.interpolate({ range: [0, 1], output: [0, 1] });
    const scale = (output: number[]) => hovering.interpolate({ range: [0, 1], output }).interpolate((r) => `scale(${r})`);

    const transPointer = (x: number, y: number) => `translate3d(${x}px,${y}px,0)`;
    const transRing = (x: number, y: number) => `translate3d(${x}px,${y}px,0)`;
    
    const getCursorTypeIcon = (cursorType) => {
      const ct = cursorTypeConfig[cursorType];
      
      return ct ? ct.eyeIcon : false
    };
    
    return (
      <a.div className={s(s.cursor, cursorType ? cursorType : '')}>
        <a.div className={s.cursor__inner} style={{ transform: xy.interpolate(transPointer) }} />
        <a.div className={s.cursor__stroke} style={{ transform: xyRing.interpolate(transRing) }}>
          <Ring cursorType={cursorTypeConfig[cursorType]} />
        </a.div>
        <a.div style={{ transform: xyText.interpolate(transPointer) }}>
          <a.div style={{ opacity: opacity }}>
            <Circle cursorType={cursorTypeConfig[cursorType]} />
          </a.div>
        </a.div>
        <a.div className={s.cursor__icon} style={{ transform: xy.interpolate(transPointer), opacity: opacity }}>
          <Eye />
        </a.div>
      </a.div>
    );
  }
;
