import React from 'react';
import { AnimationWrapper } from '../animationWrapper/AnimationWrapper';

interface LinkProps {
  children: React.ReactNode;
  to: string;
  [key: string]: any;
}

export const CustomLink = ({ children, to, ...props }: LinkProps) => (
  <AnimationWrapper path={to}>
    <div {...props}>
      {children}
    </div>
  </AnimationWrapper>
);
