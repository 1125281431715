import React, { FC, RefObject, useMemo } from 'react';
import { IntlContextConsumer, useIntl } from 'gatsby-plugin-intl';

import { useAppDispatchContext, useAppStateContext } from '../../context/appContext';
import { Row } from '../row/Row';
import { Language } from '../languages/Language';
import IntobelloLogo from 'assets/svg/intobello-logo.svg';

import s from './Header.scss';

export enum HeaderColors {
  WHITE = 'white',
  BLACK = 'black',
}

interface HeaderProps {
  color?: HeaderColors;
  isMenuOpen: boolean;
  forwardRefLogo: RefObject<HTMLDivElement>;
  forwardedRefborderLeft: RefObject<HTMLDivElement>;
  forwardedRefborderRight: RefObject<HTMLDivElement>;
  forwardedRefhamburger: RefObject<HTMLDivElement>;
  forwardedRefLanguage: RefObject<HTMLDivElement>;
  forwardedRefLanguageText: RefObject<HTMLDivElement>;
  refWrapper: RefObject<HTMLDivElement>;
  currentLang: any;

  toggleMenu(): void;
}

export const Header: FC<HeaderProps> = (props) => {
  const {
    color,
    isMenuOpen,
    toggleMenu,
    forwardRefLogo,
    forwardedRefborderLeft,
    forwardedRefborderRight,
    forwardedRefhamburger,
    forwardedRefLanguage,
    forwardedRefLanguageText,
    refWrapper,
    currentLang,
  } = props;

  const intl = useIntl();
  const dispatch = useAppDispatchContext();

  const onCursor = cursorType => {
    cursorType = cursorType ? cursorType : false;
    dispatch({ type: 'CURSOR_TYPE', cursorType: cursorType });
  };

  const getCorrectCursorColor = (color) => {
    return color === 'black' ? 'action' : 'actionWhite';
  };

  return useMemo(() => {
    return (
      <header className={s(s.header, color, { active: isMenuOpen })} id={'anchorHeader'} ref={refWrapper}>
        <Row>
          <div className={s.header__container}>
            <div className={s.header__content}>
              <div className={s.header__navigation}>
                <div ref={forwardedRefLanguage}
                     onMouseOver={() => onCursor(getCorrectCursorColor(color))}
                     onMouseLeave={() => onCursor()}
                >
                  <Language
                    color={color}
                    currentLang={currentLang}
                    isMenuOpen={isMenuOpen}
                  />
                </div>
                <div className={s(s.header__languageText, color, { white: isMenuOpen })} ref={forwardedRefLanguageText}
                >
                  {intl.formatMessage({ id: 'languageText' })}
                </div>
                <div className={s.header__border} ref={forwardedRefborderLeft} />
              </div>
              <a href="/" className={s.header__logo} onClick={() => {
                isMenuOpen && toggleMenu();
              }}>
                <div ref={forwardRefLogo}>
                  <IntobelloLogo className={s.header__logoSvg} />
                </div>
              </a>
              <div className={s(s.header__menu)}>
                <div className={s(s.header__menuTrigger)}
                     ref={forwardedRefhamburger}
                     onClick={() => toggleMenu()}
                     onMouseOver={() => onCursor(getCorrectCursorColor(color))}
                     onMouseLeave={() => onCursor()}
                >
                  <span className={s.header__menuText}>MENU</span>
                  <div className={s(s.header__hamburger, { animate: isMenuOpen })} />
                </div>
                <div className={s.header__border} ref={forwardedRefborderRight} />
              </div>
            </div>
          </div>
        </Row>
      </header>
    );
  }, [isMenuOpen, color, currentLang]);
};
