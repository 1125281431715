import React, { useState } from 'react';

/**
 * Custom hook to retrieve and store cookies for our application.
 * @param {String} key The key to store our data to
 * @param {String} defaultValue The default value to return in case the cookie doesn't exist
 */

const getItem = key => {
  if(typeof window !== 'undefined' && typeof document !== 'undefined') {
    let returnKey = false;
    
    document.cookie.split('; ').reduce((total, currentCookie) => {
      const item = currentCookie.split('=');
      const storedKey = item[0];
      const storedValue = item[1];
      returnKey = key === storedKey
        ? decodeURIComponent(storedValue)
        : false;
    }, false);
    
    return returnKey;
  }
};


const setItem = (key, value, numberOfDays) => {
  if(typeof window !== 'undefined' && typeof document !== 'undefined') {
    const now = new Date();
    // set the time to be now + numberOfDays
    now.setTime(now.getTime() + (numberOfDays * 60 * 60 * 24 * 1000));
    document.cookie = `${key}=${value}; expires=${now.toUTCString()}; path=/;`;
  }
};

export const useCookie = (key, defaultValue) => {
  const getCookie = getItem(key);
  const cookieValue = getCookie || defaultValue;
  const [cookie, setCookie] = useState(cookieValue === 'true');

  const updateCookie = (value, numberOfDays) => {
    setCookie(value);
    setItem(key, value, numberOfDays);
  };

  return [cookie, updateCookie];
};
