// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-collection-template-tsx": () => import("./../src/templates/collectionTemplate.tsx" /* webpackChunkName: "component---src-templates-collection-template-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-pages-try-another-way-tsx": () => import("./../src/pages/try-another-way.tsx" /* webpackChunkName: "component---src-pages-try-another-way-tsx" */)
}

