import React, { useEffect, useRef, useState } from 'react';
import { IntlContextConsumer, changeLocale } from 'gatsby-plugin-intl';

import { useOnClickOutside } from '../../hooks/use-click-outside';

import s from '../languages/language.scss';

interface ILanguages {
  [key: string]: string;
}

interface IIntlProps {
  languages: string[];
  language: string;
}

const languageName: ILanguages = {
  en: 'EN',
  fr: 'FR',
};

export const Language = ({ color, currentLang, isMenuOpen }) => {
  const [displayDropdownMenu, setDisplay] = useState(false);
  const [selectedLang, setSelectedLang] = useState(currentLang);

  const ref = useRef();
  useOnClickOutside(ref, () => setDisplay(false));

  const onClickHandler = (language: string) => {
    return (e: any) => {
      e.preventDefault();
      changeLocale(language === process.env.DEFAULT_LANGUAGE ? '' : language);
      setSelectedLang(language);
      setDisplay(!displayDropdownMenu);
    };
  };

  return (
    <ul className={s.language__navigation} ref={ref}>
      <li
        className={s(s.language__item, color, { isActive: displayDropdownMenu, white: isMenuOpen })}
        onClick={() => {
          setDisplay(!displayDropdownMenu);
        }}
      >
        <div
          className={s(s.language__shortcut, color, { white: isMenuOpen })}
        >
          {languageName[selectedLang]}
        </div>
        <div className={displayDropdownMenu ? s(s.language__arrow, color, {
          isActive: displayDropdownMenu,
          white: isMenuOpen,
        }) : s(s.language__arrow, color, { white: isMenuOpen })} />
      </li>
      <div className={s.language__dropdown}>
        <IntlContextConsumer>
          {({ languages, language: currentLocale }: IIntlProps) =>
            displayDropdownMenu && languages.map((language, i) => language === currentLocale ? null : (
              <li className={s(s.language__item, color, { dropdown: displayDropdownMenu, white: isMenuOpen })} key={i}>
                <a
                  key={language}
                  href={`/${language}`}
                  onClick={onClickHandler(language)}
                >
                  <div
                    className={s(s.language__shortcut, color, { dropdown: displayDropdownMenu, white: isMenuOpen })}
                    id={`navigation.${language}`}
                  >
                    {languageName[language]}
                  </div>
                </a>
                <div className={s(s.language__arrow, color, { white: isMenuOpen })} />
              </li>
            ))}
        </IntlContextConsumer>
      </div>
    </ul>
  );
};
