import React, { ReactNode } from 'react';

import { CustomLink } from 'components/link/CustomLink';

import s from './HeaderLink.scss';

interface HeaderLinkProps {
  name?: string;
  to: string;
  icon?: ReactNode;
  color?: string;
}

export const HeaderLink = ({ name, to, icon, color }: HeaderLinkProps) => {
  const isLink = typeof to !== 'undefined';
  const isExternal = isLink && /^((https?:)?\/\/|[0-9a-zA-Z]+:)/.test(to || '');

  const content = () => (
    <>
      {icon && React.cloneElement(icon as any, { className: s(s.link__icon, color) })}
      {name}
    </>
  );

  if (isExternal) {
    return (
      <a className={s.link} target="_blank" rel="noopener noreferrer" href={to}>
        {content()}
      </a>
    );
  }

  return (
    <CustomLink className={s.link} to={to}>
      {content()}
    </CustomLink>
  );
};
