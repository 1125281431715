import React, { ReactNode } from 'react';
import { IntlContextConsumer } from 'gatsby-plugin-intl';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

interface FormWrapperProps {
  children: ReactNode,
  path: string
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const AnimationWrapper = ({ children, path, onMouseEnter, onMouseLeave }: FormWrapperProps) => {
  const preparePath = (lang, path) => {
    if (lang === process.env.DEFAULT_LANGUAGE) {
      return path;
    }

    return `/${lang}/${path}`;
  };

  return (
    <IntlContextConsumer>
      {({ language: lang }) => (
        <AniLink
          to={preparePath(lang, path)}
          cover
          direction="right"
          duration={1.8}
          bg="
                  center / cover   
                  no-repeat       
                  fixed            
                  padding-box     
                  content-box      
                  #7d133b           
                "
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {children}
        </AniLink>
      )}
    </IntlContextConsumer>
  );
};
