import React, { useMemo } from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import { Container } from 'components/container/Container';
import IntobelloLogo from 'assets/svg/ib_footer_logo.svg';
import Arrow from 'assets/svg/arrow.svg';

import s from './Footer.scss';
import { SocialMedia } from '../social-media/SocialMedia';
import { gsap, Power2, ScrollToPlugin, TweenLite } from 'gsap/all';

export const Footer = () => {
  const intl = useIntl();

  function gotoAnchor() {

    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const anchor = document.getElementById('anchorHeader');
      const posTop = anchor && anchor.offsetTop;
      gsap.registerPlugin(ScrollToPlugin);

      TweenLite.to(window, 1, { scrollTo: { y: posTop }, ease: Power2.easeOut });
    }
  }

  return useMemo(() => {
    return (
      <div className={s.footer}>
        <Container>
          <div className={s.footer__skeleton}>
            <div className={s(s.footer__copyright, s.footer__content)}>
              <p className={s.footer__content}>COPYRIGHT © 2020 <span
                className={s.social__bold}>{`${String.fromCharCode(160)}INTOBELLO.${String.fromCharCode(160)}`}</span> {intl.formatMessage({ id: 'menuFooterPart2' })}
              </p>
            </div>
            <div className={s(s.footer__logo, s.footer__content)}>
              <a href="/">
                <IntobelloLogo className={s.footer__logoSvg} />
              </a>
            </div>
            <div className={s(s.footer__brand, s.footer__content)}>
              <p className={s.footer__content}>{`${intl.formatMessage({ id: 'menuFooterPart3' })} `}
                <a 
                  href={'http://diffarts.com/'}
                  className={s.social__bold}
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  {`${String.fromCharCode(160)}DIFFERENTARTS${String.fromCharCode(160)}`}
                </a>
              </p>
            </div>
            <div className={s(s.footer__office, s.footer__content)}>
              <div className={s(s.footer__officeDetail, s.footer__content)}>
                OFFICE@INTOBELLO.COM
              </div>
              <div className={s(s.footer__officeDetail, s.footer__content)}>
                UL. GRZYBOWSKA 87, 00-844 WARSZAWA
              </div>
            </div>
            <div className={s(s.footer__arrow, s.footer__content)}>
              <a onClick={gotoAnchor}>
                <Arrow className={s.footer__arrowSvg} />
              </a>
            </div>
            <div className={s(s.footer__social, s.footer__content)}>
              <SocialMedia footer />
            </div>
          </div>
        </Container>
      </div>
    );
  }, []);
};
