import React, { useEffect, useMemo, useRef, useState } from 'react';
import uuid from 'react-uuid';
import { useIntl } from 'gatsby-plugin-intl';
import { gsap, Power2, Power3, TimelineLite, TimelineMax } from 'gsap';

import { useAppDispatchContext } from '../../context/appContext';
import { useMobile } from '../../hooks/use-mobile';
import { useBodyClass } from '../../hooks/use-body-class';
import { Button } from '../button/Button';
import { Container } from '../container/Container';
import { AnimationWrapper } from '../animationWrapper/AnimationWrapper';
import { useQueryCollections } from '../../hooks/use-query-collections';
import Img from 'gatsby-image';
import { SocialMedia } from '../social-media/SocialMedia';
import offerIntobello from '../../../static/offer-intobello.pdf';

import s from './Menu.scss';

export interface IMenu {
  isMenuOpen: boolean;

  toggleMenu(): void;
}

export const Menu: React.FC<IMenu> = ({ isMenuOpen, toggleMenu }) => {
  const intl = useIntl();

  const { nodes } = useQueryCollections();

  const [collection, setCollection] = useState<string>('');
  const [collectionIndex, setCollectionIndex] = useState<number>(-1);
  const sublinkWrapper = useRef();

  const isMobile = useMobile();

  useBodyClass(isMenuOpen);

  const dispatch = useAppDispatchContext();

  const onCursor = cursorType => {
    cursorType = cursorType ? cursorType : false;
    dispatch({ type: 'CURSOR_TYPE', cursorType: cursorType });
  };

  const [isCollectionsExpanded, setCollectionsExpanded] = useState(!isMobile);

  const toggleCollectionsExpanded = () => {
    isMobile && setCollectionsExpanded(!isCollectionsExpanded);
  };

  const isVisible = (name: string) => name === collection;

  const changeCollection = (name: string) => {
    setCollection(name);
  };


  const content = useRef();
  const content2 = useRef();
  const content3 = useRef();
  const content4 = useRef();
  const content5 = useRef();

  useEffect(() => {
    const tl = gsap.timeline();

    if (isMenuOpen) {
      const lines = content.current?.children;
      const lines3 = content3.current?.children;
      const lines4 = content4.current?.children;
      const lines5 = content5.current?.children;

      gsap.set([
        lines,
        lines3,
        lines4,
        lines5,
      ], { autoAlpha: 0 });
      tl.addLabel('start');
      tl.fromTo([
          lines,
          lines3,
          lines4,
          lines5,
        ],
        { autoAlpha: 0, y: 25 },
        {
          duration: 1.2,
          y: 0,
          autoAlpha: 1,
          ease: 'expo',
          stagger: .12,
        },
        'start');
    }
  }, [isMenuOpen]);

  const previousOne = useRef<number>();

  const animateVisible = (imageIndex: number, tl) => {
    const lineBlock = sublinkWrapper.current?.children[imageIndex]
      .children[0]
      .children[0]
      .children[0]
      .children[1];


    const imageBlock = sublinkWrapper.current?.children[imageIndex]
      .children[0]
      .children[0]
      .children[0]
      .children[2];

    gsap.set(lineBlock, { scaleX: 0 });
    tl.to(lineBlock, {
      transformOrigin: 'right',
      ease: Power3.easeOut,
      scaleX: 1,
      opacity: 1,
      duration: 1,
    }, 'start')
      .to(imageBlock, {
        opacity: 1,
        duration: 2,
        ease: 'power2.easeIn',
      }, 'start');
  };


  const animateInvisible = (imageIndex, tl) => {
    if (typeof imageIndex !== 'undefined') {
      const imageBlock = sublinkWrapper.current?.children[imageIndex]
        .children[0]
        .children[0]
        .children[0]
        .children[2];

      const lineBlock = sublinkWrapper.current?.children[imageIndex]
        .children[0]
        .children[0]
        .children[0]
        .children[1];

      tl
        .to(imageBlock, {
          opacity: 0,
          duration: 1,
        }, 'start')
        .fromTo(lineBlock, {
          transformOrigin: 'right',
          ease: Power3.easeOut,
          scaleX: 1,
          opacity: 1,
        }, {
          scaleX: 0,
          transformOrigin: 'right',
          ease: Power2.easeOut,
          duration: 1,
        }, 'start');
    }
  };

  useEffect(() => {
    const tl = gsap.timeline().addLabel('start');
    if (sublinkWrapper.current !== null) {
      animateInvisible(previousOne.current, tl);
      if (collectionIndex !== -1) {
        animateVisible(collectionIndex, tl);
      }
    }

    return () => {
      tl.kill();
    };

  }, [collectionIndex, sublinkWrapper]);


  return (
    <div className={s(s.menu, { active: isMenuOpen })}>
      <Container>
        <nav className={s.menu__navigation}>
          <ul className={s.menu__pages} ref={content}>
            <AnimationWrapper
              path={'/'}
            >
              <div className={s.menu__pageLink}
                   onMouseEnter={() => onCursor('collectionMenuHover')}
                   onMouseLeave={() => onCursor('')}
                   onClick={() => toggleMenu()}
              >
                <li className={s(s.menu__link, s.menu__page)}>{intl.formatMessage({ id: 'menuHome' })}</li>
              </div>
            </AnimationWrapper>
            <AnimationWrapper path={'/about-us'}>
              <div className={s.menu__pageLink}
                   onMouseEnter={() => onCursor('collectionMenuHover')}
                   onMouseLeave={() => onCursor('')}
                   onClick={() => toggleMenu()}
              >
                <li className={s(s.menu__link, s.menu__page)}>{intl.formatMessage({ id: 'menuAboutUs' })}</li>
              </div>
            </AnimationWrapper>
            <AnimationWrapper path={'/contact'}>
              <div className={s.menu__pageLink}
                   onMouseEnter={() => onCursor('collectionMenuHover')}
                   onMouseLeave={() => onCursor('')}
                   onClick={() => toggleMenu()}
              >
                <li className={s(s.menu__link, s.menu__page)}>{intl.formatMessage({ id: 'menuContact' })}</li>
              </div>
            </AnimationWrapper>
          </ul>

          <ul className={s.menu__collections} ref={content2}>
            <p className={s(s.menu__link, s.menu__collectionsTitle)}
               onClick={toggleCollectionsExpanded}>{intl.formatMessage({ id: 'collection' })}</p>
            <div className={s(s.menu__sublinkWrapper, { active: isCollectionsExpanded })}
                 ref={sublinkWrapper}
                 onMouseLeave={() => {
                   previousOne.current = collectionIndex;
                   setCollectionIndex(-1);
                 }}
            >
              {nodes.map((node, i) =>
                <AnimationWrapper path={node.frontmatter.path} key={uuid()}>
                  <div
                    onClick={() => toggleMenu()}
                    onMouseEnter={() => onCursor('collectionMenuHover')}
                    onMouseLeave={() => onCursor('')}
                  >
                    <li
                      className={s(s.menu__sublink, s.menu__collection)}
                      onMouseEnter={() => {
                        changeCollection(node.frontmatter.title);
                        setCollectionIndex(i);
                      }}
                      onMouseLeave={() => {
                        changeCollection('');
                        previousOne.current = collectionIndex;
                      }}
                    >
                      <div className={s.menu__preview}>
                      <span
                        className={s(s.menu__textBlock, { active: isVisible(node.frontmatter.title) })}>
                        {node.frontmatter.title}
                      </span>
                        <div className={s.menu__lineBlock}>
                          <hr className={s.menu__line} />
                        </div>
                        <div className={s.menu__imageBlock}>
                          <Img fluid={node.frontmatter.featured.childImageSharp.fluid} />
                        </div>
                      </div>
                    </li>
                  </div>
                </AnimationWrapper>,
              )}
            </div>
          </ul>

          <div className={s.menu__socialPlaceholder} ref={content3}>
            <SocialMedia menu />
          </div>

          <aside className={s.menu__presspack} ref={content4}>
            <div className={s.menu__downloadWrapper}>
              <header className={s.menu__filename}>{intl.formatMessage({ id: 'offerPDF' })}</header>
              <Button className={s.menu__button} color={'accent'} href={offerIntobello}>
                {intl.formatMessage({ id: 'downloadButton' })}
              </Button>
            </div>
          </aside>

        </nav>
        <footer className={s.menu__footer} ref={content5}>
          <hr className={s.menu__separator} />
          <p
            className={s.menu__copyright}>{intl.formatMessage({ id: 'menuFooterPart1' })}<strong>Intobello</strong> {intl.formatMessage({ id: 'menuFooterPart2' })}
          </p>
        </footer>
      </Container>
    </div>
  );
};
