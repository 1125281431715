import React, { ReactNode } from 'react';

import { CustomLink } from 'components/link/CustomLink';

import s from './Button.scss';

interface ButtonProps {
  children: ReactNode;
  href?: string;
  className?: string;
  color?: string;
  thanksButton?: boolean;
  collectionSlider?: boolean;

  [key: string]: any;

  onClick?(): void;
}

export const Button = (props: ButtonProps) => {
  const { children, className, href, color, thanksButton, onClick, ...rest } = props;
  const passProps = { ...rest };
  const isLink = typeof href !== 'undefined';
  const isExternal = isLink && /^((https?:)?\/\/|[0-9a-zA-Z]+:)/.test(href || '');
  const isThanksButton = typeof thanksButton !== 'undefined';
  const isCollectionSlider = typeof collectionSlider !== 'undefined';
  const downloadableFile = /\.[0-9a-z]+$/i.test(href || '');

  passProps.className = s(s.button, className, color);

  if (isThanksButton) {
    return (
      <button {...passProps} onClick={onClick}>
        {children}
      </button>
    );
  }

  if (downloadableFile) {
    return (
      <a {...passProps} href={href} target={'_blank'} download={href}>
        {children}
      </a>
    );
  }

  if (isExternal) {
    return (
      <a {...passProps} target="_blank" rel="noopener noreferrer" href={href}>
        {children}
      </a>
    );
  }

  if (isLink) {
    return (
      <CustomLink {...passProps} to={href || '#'}>
        {children}
      </CustomLink>
    );
  }

  return (
    <button className={s.button} onClick={onClick}>
      {children}
    </button>
  );
};

Button.defaultProps = {
  color: 'white',
};
